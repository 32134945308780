import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo/SEO';
import FeaturedResorts from '../components/FeaturedResorts';
import FeaturedTestimonial from '../components/FeaturedTestimonial';
import useI18n from '../utils/useI18n';
import Hero from '../components/Hero';
import VideoAbout from '../components/VideoAbout';
import Benefits from '../components/Benefits';
import Link from '../components/Link';
import '../styles/components/_components.stat.scss';

const IndexPage = ({ pageContext: { switchLang } }) => {
  const { t } = useI18n();
  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={t('home.meta.title')}
        description={t('home.meta.description')}
        keywords={t('home.meta.keywords')}
        pathname="/"
        organization
      />
      <Hero
        title={t('home.hero.title')}
        callout={
          <p
            className="o-callout o-callout--light u-6/12@desktop u-push-3/12@desktop"
            dangerouslySetInnerHTML={{ __html: t('home.hero.callout') }}
          />
        }
        extra={
          <>
            <Link className="c-button c-button--huge c-button--light" to={`${t('url.contact')}/`}>
              {t('home.hero.button')}
            </Link>
            <a className="c-hero__phone" href={`tel:${t('phone')}`}>
              {t('phone')}
            </a>
            <span className="c_hero__time">{t('time')}</span>
          </>
        }
      />
      <section>
        <div className="o-wrapper c-section">
          <h2 className="o-largeTitle">{t('home.about.title')}</h2>
          <div
            className="o-callout u-8/12@desktop u-push-2/12@desktop u-12/12@tablet"
            dangerouslySetInnerHTML={{ __html: t('home.about.content') }}
          />
          <div className="o-layout">
            <div className="o-layout__item u-3/12@desktop u-6/12@tablet c-stat">
              <strong className="u-h3 c-stat__value">+13500</strong>
              <span>{t('home.about.contractCancelled')}</span>
            </div>
            <div className="o-layout__item u-3/12@desktop u-6/12@tablet c-stat">
              <strong className="u-h3 c-stat__value">+25</strong>
              <span>{t('home.about.years')}</span>
            </div>
            <div className="o-layout__item u-3/12@desktop u-6/12@tablet c-stat">
              <strong className="u-h3 c-stat__value">15</strong>
              <span>{t('home.about.professionals')}</span>
            </div>
            <div className="o-layout__item u-3/12@desktop u-6/12@tablet c-stat">
              <strong className="u-h3 c-stat__value">100%</strong>
              <span>{t('home.about.rate')}</span>
            </div>
          </div>
        </div>
      </section>
      <VideoAbout />
      <Benefits />
      <FeaturedTestimonial />
      <FeaturedResorts />
    </Layout>
  );
};

export default IndexPage;
