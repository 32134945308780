import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ModalVideo from 'react-modal-video';
import { CaretRightOutlined } from '@ant-design/icons';

import useI18n from '../utils/useI18n';
import '../styles/components/_components.videoAbout.scss';

const isBrowser = typeof window !== 'undefined';

const VideoAbout = () => {
  const [visible, setVisible] = useState(false);
  const { t } = useI18n();
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "about-timeshare-cancellation.webp" }) {
        childImageSharp {
          fluid(maxWidth: 516) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const toggleVideo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.gtag) {
      window.gtag('event', 'reproducir', {
        event_category: 'videos',
        event_label: 'home',
        value: 1,
      });
    }
    setVisible(!visible);
  };

  return (
    <>
      <section className="c-videoAbout">
        <div className="c-videoAbout__content o-wrapper">
          <a
            className="c-videoAbout__link noGatsbyLink"
            href="https://www.youtube.com/watch?v=Ps7wyTzqJrw"
            onClick={toggleVideo}
          >
            <span className="c-videoAbout__icon">
              <CaretRightOutlined />
            </span>
            <div className="c-videoAbout__body">
              <h2 className="c-videoAbout__title">{t('video.title')}</h2>
              <strong>{t('video.description')}</strong>
            </div>
          </a>
          <div className="c-videoAbout__img">
            <Img fluid={data.placeholderImage.childImageSharp.fluid} fadeIn={false} />
          </div>
        </div>
      </section>
      {isBrowser && (
        <ModalVideo
          channel="youtube"
          isOpen={visible}
          videoId="Ps7wyTzqJrw"
          onClose={() => setVisible(false)}
        />
      )}
    </>
  );
};

export default VideoAbout;
