import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RightCircleOutlined } from '@ant-design/icons';

import useI18n from '../utils/useI18n';
import Resort from './Resort';
import Link from '../components/Link';
import ContactButton from './ContactButton';

const FeaturedResorts = () => {
  const { t } = useI18n();
  const data = useStaticQuery(graphql`
    query FeaturedHotelsQuery {
      Hotel: allHotel(limit: 4, skip: 0) {
        nodes {
          id
          Hotel {
            id
            slug
            titulo
            comments
          }
          featuredImg {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 338, quality: 60) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <section className="c-section o-wrapper">
      <div className="u-10/12@desktop u-push-1/12@desktop">
        <h2 className="o-largeTitle">{t('featuredResorts.title')}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: t('featuredResorts.text', { url: t('url.blacklist') }),
          }}
        />
      </div>
      <ul className="c-resorts o-layout">
        {data.Hotel.nodes.map((record) => (
          <li className="o-layout__item u-3/12@desktop u-6/12@tablet" key={record.id}>
            <Resort record={record} />
          </li>
        ))}
      </ul>
      <div className="u-margin-bottom-large">
        <Link to={`${t('url.blacklist')}/`}>
          {t('featuredResorts.viewMore')} <RightCircleOutlined />
        </Link>
      </div>
      <p className="o-callout">{t('featuredResorts.secondaryText')}</p>
      <strong className="u-h1 u-margin-bottom">{t('featuredResorts.noPayments')}</strong>
      <ContactButton />
    </section>
  );
};

export default FeaturedResorts;
